import React from 'react';

export interface RowProps {
  children?: React.ReactNode;
}

function RowComponent({
  children = undefined,
}: RowProps) {
  return (
    <div className="planner-row border-bottom">
      {children}
    </div>
  );
}

export const Row = React.memo(RowComponent);
