import React from 'react';
import { Row } from './row';
import { Cell } from './cell';
import { FirstLevelHeader } from './first-level-header';
import { SecondLevelHeader } from './second-level-header';
import { Action, PlannerData } from '../planner-data.interface';
import { floatFormat } from '../utils';
import { ABORT_POSITION_CB_IDENTIFIER } from '../constants';

export interface FreezedPaneProps {
  resourceGroups: PlannerData['resourceGroups'];
  totals: PlannerData['totals'];
  dispatch: React.Dispatch<Action>;
  order: PlannerData['order'];
}

/**
 * Sub-component for the freezed section of the planner component.
 */
function FreezedPaneComponent({
  resourceGroups,
  totals,
  dispatch,
  order,
}: FreezedPaneProps
) {
  const orderBy = order[0] === '-' ? order.slice(1) : order;
  const orderIsDesc = order[0] === '-';

  return (
    <div className="freezed-pane shadow">
      <Row key="months-spacer" />
      <Row key="column-headers">
        <div className="ms-auto d-flex fw-bold w-100">
          <Cell style={{ flexGrow: 1 }}>
            {/* TODO: Move this button to a separate component when other order links are needed */}
            <button
              type="button"
              className={`btn btn-link w-100 d-flex justify-content-between align-items-center ps-3 my-auto text-decoration-none rounded-0 ${ABORT_POSITION_CB_IDENTIFIER}`}
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'changeOrder',
                  order: order === 'name' ? '-name' : 'name',
                });
              }}
              aria-label={`Activate to sort column ${order === 'name' ? 'descending' : 'ascending'}`}
            >
              Name
              {orderBy === 'name' ? (
                <span className={`fa fa-sort-${orderIsDesc ? 'down' : 'up'}`} aria-hidden />
              ) : (
                <span className="fa fa-sort" aria-hidden />
              )}
            </button>
          </Cell>
          <Cell><p className="m-auto">UF</p></Cell>
          <Cell><p className="m-auto">Rate</p></Cell>
          <Cell><p className="m-auto">HH</p></Cell>
        </div>
      </Row>
      {resourceGroups.map((resourceGroup) => (
        <React.Fragment key={`rg${resourceGroup.id}`}>
          <Row key={`rg${resourceGroup.id}`}>
            <FirstLevelHeader resourceGroup={resourceGroup} />
          </Row>
          {resourceGroup.resources.map((resource) => (
            <Row key={`rg${resourceGroup.id}-ro${resource.roleId}-r${resource.id}`}>
              <SecondLevelHeader resource={resource} />
            </Row>
          ))}
        </React.Fragment>
      ))}
      <Row key="totals">
        <div className="first-level-header ps-3 my-auto fw-bold">
          <p className="m-auto">Total</p>
        </div>
        <div className="ms-auto d-flex border-start">
          <Cell><p className="m-auto">{floatFormat(totals.clf)}</p></Cell>
          <Cell><p className="m-auto">{floatFormat(totals.rate)}</p></Cell>
          <Cell><p className="m-auto">{floatFormat(totals.plannedHours)}</p></Cell>
        </div>
      </Row>
    </div>
  );
}

export const FreezedPane = React.memo(FreezedPaneComponent);
