import React from 'react';
import { Selection, Action, PlannerData } from '../planner-data.interface';

type SubtotalProps = {
  workload: {
    plannedPercentage?: number,
    plannedHours: number,
    reportedHours: number,
    hiredHours: number,
  },
  todayStr: string,
  dateStr: string,
  resourceGroupId: number,
  // eslint-disable-next-line react/no-unused-prop-types -- Actually used but not detected
  selection: Selection,
  dispatch: React.Dispatch<Action>,
  holidays: PlannerData['holidays'];
};

// We use -1 as the id for the subtotal rows, since the backend is never going to use that
const SubtotalSelectionResourceId = -1;
const SubtotalSelectionRoleId = -1;

/**
 * Checks if given a selection state, the current cell is selected or not.
 * @returns true if the cell is selected.
 */
function checkSelection(props: SubtotalProps) {
  if (props.selection.startDate === null || props.selection.endDate === null) {
    return false;
  }

  return props.selection.resourceGroupId === props.resourceGroupId
    && props.selection.resourceId === SubtotalSelectionResourceId
    && props.selection.roleId === SubtotalSelectionRoleId
    && props.selection.startDate <= props.dateStr
    && props.selection.endDate >= props.dateStr;
}

/**
 * Used as the second parameter of React.memo, it allows us to skip the re-rendering
 * of the Subtotal component, since there is a lot of them. This looks like a lot of
 * work, but is faster than the re-rendering plus the diffing algorithm in react.
 * @returns true to skip rendering, false to re-render.
 */
function shouldSkipRendering(prevProps: SubtotalProps, nextProps: SubtotalProps): boolean {
  // Skip rendering if trivially the same state
  if (prevProps === nextProps) {
    return true;
  }

  // Skip rendering if trivially the same workload and selection
  if (prevProps.workload === nextProps.workload && prevProps.selection === nextProps.selection) {
    return true;
  }

  // Check if selection didn't change
  const wasSelected = checkSelection(prevProps);
  const isSelected = checkSelection(nextProps);
  const isSelecting = prevProps.selection.isSelecting && nextProps.selection.isSelecting;
  if (isSelecting && (wasSelected === isSelected)) {
    return true;
  }

  // Any other case, re-render
  return false;
}

/**
 * Checks if the planned hours exceeded the available hours, or if the planned percentage
 * is more than 100%
 * @returns true if the planning exceeded the available time
 */
function checkPlanningOverflow(props: SubtotalProps) {
  if (props.workload === undefined || props.workload.plannedPercentage === undefined) {
    return false;
  }
  return props.workload.plannedPercentage > 100
    || props.workload.plannedHours > props.workload.hiredHours;
}

/**
 * Component that represents a total for a column aggregate in a resource group.
 */
function SubtotalComponent(props: SubtotalProps) {
  const isSelected = checkSelection(props);
  const planningTimeWasExceeded = checkPlanningOverflow(props);
  const resourceIsNotAvailable = props.workload.hiredHours === 0
    && !props.holidays.includes(props.dateStr);

  let reportedValue = '0';
  let plannedValue = '0';
  let showValues = false;
  let showPercentage = false;
  let percentageWidth = 0;
  let percentageClass = '';

  if (props.workload) {
    if (props.dateStr < props.todayStr) {
      if (props.workload.plannedHours > 0 || props.workload.reportedHours > 0) {
        showValues = true;
        reportedValue = props.workload.reportedHours.toString();
        plannedValue = props.workload.plannedHours.toString();
      }
    } else if (props.workload.plannedHours > 0) {
      showValues = true;
      plannedValue = props.workload.plannedHours.toString();
    }

    if (resourceIsNotAvailable) {
      showValues = true;
      showPercentage = true;
      percentageClass = 'disabled';
      percentageWidth = 100;
    }

    if (props.workload.plannedPercentage !== undefined && props.workload.plannedHours > 0) {
      showPercentage = true;
      percentageWidth = Math.max(0, Math.min(100, props.workload.plannedPercentage));
      if (planningTimeWasExceeded) {
        percentageClass = 'overflow';
      } else if (props.workload.plannedPercentage < 100) {
        percentageClass = 'incomplete';
      }
    }
  }

  return (
    <div
      className={`subtotal m-auto ${isSelected ? 'selected' : ''}`}
      onMouseEnter={() => props.dispatch({
        type: 'mouseEnter', date: props.dateStr, resourceGroupId: props.resourceGroupId, resourceId: SubtotalSelectionResourceId, roleId: SubtotalSelectionRoleId
      })}
      onMouseDown={() => props.dispatch({
        type: 'mouseDown', date: props.dateStr, resourceGroupId: props.resourceGroupId, resourceId: SubtotalSelectionResourceId, roleId: SubtotalSelectionRoleId
      })}
      role="presentation"
    >
      {showPercentage && (
        <div className="progress-container">
          <div className={`progress-fill ${percentageClass}`} style={{ width: `${percentageWidth}%` }} />
        </div>
      )}
      <div className="text-center m-auto">
        {showValues && (
          <>
            {props.dateStr < props.todayStr && <div>{reportedValue}</div>}
            <div>{plannedValue}</div>
          </>
        )}
      </div>
    </div>
  );
}

export const Subtotal = React.memo(SubtotalComponent, shouldSkipRendering);
