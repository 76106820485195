import React from 'react';
import { Action, ResourceInGroup } from '../../planner-data.interface';
import { Buttons } from './components/buttons';
import { CreateForm } from './components/create-form';
import { EditForm } from './components/edit-form';
import { DeleteForm } from './components/delete-form';

type Position = { x: number, y: number };

const popupOffset: Position = { x: 15, y: -15 };

export interface PopupProps {
  position: Position;
  show: 'buttons' | 'create' | 'delete' | 'update' | 'none';
  dispatch: React.Dispatch<Action>;
  resources: ResourceInGroup[];
  defaultHours?: number;
}

export function Popup({
  position,
  show,
  dispatch,
  resources,
  defaultHours = 0
}: PopupProps) {
  const style = {
    left: `${position.x + popupOffset.x}px`,
    top: `${position.y + popupOffset.y}px`
  };

  if (!show || show === 'none') {
    return null;
  }

  return (
    <div
      role="none"
      className="popup card shadow-lg border-0"
      style={style}
      onMouseUp={(e) => e.stopPropagation()}
    >
      {show === 'buttons' && <Buttons dispatch={dispatch} />}
      {show === 'create' && <CreateForm dispatch={dispatch} resources={resources} />}
      {show === 'update' && <EditForm dispatch={dispatch} defaultValue={defaultHours} />}
      {show === 'delete' && <DeleteForm dispatch={dispatch} />}
    </div>
  );
}
