$(() => {
  $('.datetimepicker-input').each((i, item) => {
    const $this = $(item);
    $this.data('target', `#${$this.prop('id')}`);

    $this.datetimepicker({
      date: moment($this.val(), 'DD/MM/YYYY'),
      format: $this.data('format') || 'DD/MM/YYYY',
      locale: 'es'
    });

    // When autocomplete is "on", the browser shows a suggestion that obstructs the picker
    $this.attr('autocomplete', 'off');
  });

  $('.datetimepicker-group').each((i, item) => {
    const $this = $(item);

    $this.datetimepicker({
      date: moment($this.find('input').val(), 'DD/MM/YYYY'),
      format: $this.data('format') || 'DD/MM/YYYY',
      locale: 'es'
    });

    // When autocomplete is "on", the browser shows a suggestion that obstructs the picker
    $this.find('input').attr('autocomplete', 'off');
  });
});
