import React from 'react';

/**
 * Returns a bootstrap progress bar, with aria roles and a readable text
 * even when tha bar is too small. The value needs to be between 0.0 and 1.0
 */
export function CompletionBar(props: { value: number, title: string }) {
  const percentage = Math.round(props.value * 1000) / 10;

  return (
    <div
      className="progress position-relative"
      role="progressbar"
      aria-label={props.title}
      aria-valuenow={percentage}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div className="back-text position-absolute px-2">
        {percentage}
        %
      </div>
      <div className="progress-bar position-absolute" style={{ width: `${Math.min(100, percentage)}%` }}>
        <span className="px-2">
          {percentage}
          %
        </span>
      </div>
    </div>
  );
}
