import { Annotations as PlotlyAnnotation, Data as PlotlyData, Shape as PlotlyShape } from 'plotly.js';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { JsonPropsType } from '../../component-loader';
import { ProjectStageSeriesDataSets } from './types';
import {
  findDataBounds, generatePlotAnnotations, generatePlotData, generatePlotShapes
} from './helpers/DataProcessing';

const knownColors = {
  dateLine: '#ffff84',
  todayLine: '#ff8417',
  actualCost: '#ff6384',
  earnedValue: '#55c948',
  plannedValue: '#6384ff',
  valueBaseline: '#aae4a3',
  costBaseline: '#ffb1c1'
};

/**
 * Creates the state for the chart, adding conditional elemnets when required.
 * @param apiDataSets Full API response.
 */
const generatePlotState = (apiDataSets: ProjectStageSeriesDataSets) => {
  const bounds = findDataBounds(apiDataSets);
  let today: Date | undefined = new Date();
  const endDate = new Date(bounds.yMax);
  if (today && (endDate.getTime() - today.getTime()) > 2 * 30 * 24 * 86400 * 1000) {
    // Do not draw today line when chart is more than 2 months in the past
    today = undefined;
  }

  const plotData = generatePlotData(apiDataSets, knownColors);
  const plotShapes = generatePlotShapes(apiDataSets, bounds, knownColors, today);
  const plotAnnotations = generatePlotAnnotations(apiDataSets, knownColors, today);

  return { plotData, plotShapes, plotAnnotations };
};

/**
 * Real expected types for this component.
 * The declared types is jsonObject: unkown because the data source is
 * a json from the backend that mighy not have all its properties.
 */
interface SeriesChartProps {
  jsonObject: {
    dataUrl: string,
    mode: 'expanded' | 'compact'
  }
}

/**
 * Series chart component rendered using plotly.js.
 */
export function SeriesChart(props: JsonPropsType) {
  const { jsonObject } = props as SeriesChartProps;
  if (!jsonObject.dataUrl || !jsonObject.mode) {
    throw new Error('Missing props for SeriesChart component.');
  }
  const isExpanded = jsonObject.mode === 'expanded';

  const [series, setSeries] = useState<PlotlyData[]>([]);
  const [shapes, setShapes] = useState<Partial<PlotlyShape>[]>([]);
  const [annotations, setAnnotations] = useState<Partial<PlotlyAnnotation>[]>([]);

  useEffect(() => {
    fetch(jsonObject.dataUrl)
      .then((response) => response.json())
      .then((data) => {
        const apiDataSets = data as ProjectStageSeriesDataSets;
        const { plotData, plotShapes, plotAnnotations } = generatePlotState(apiDataSets);

        setSeries(plotData);
        setShapes(plotShapes);
        setAnnotations(plotAnnotations);
      })
      .catch(() => {});
  }, []);

  return (
    <Plot
      data={series}
      layout={{
        annotations,
        shapes,
        legend: { orientation: 'h' },
        showlegend: isExpanded,
        margin: {
          l: 35, r: 0, t: 0, b: 0
        },
        xaxis: {
          fixedrange: true,
          tickformat: '%Y-%m-%d'
        },
        yaxis: {
          fixedrange: true
        }
      }}
      useResizeHandler
      style={{ minWidth: '100%', height: isExpanded ? '450px' : '190px' }}
      config={{ displaylogo: false }}
    />
  );
}
