import { useWindowScroll } from 'react-use';

/**
 * Returns an object containing the current position values (top, bottom, left and right)
 * of the navbar.
*/
export function useNavbarPosition() {
  /*
    Calling useWindowScroll to trigger the hook everytime the window is scrolled instead of having
    to create an event handler from the ground up.
  */
  useWindowScroll();
  const navbar = document.querySelector('header nav.navbar');

  return {
    top: navbar?.getBoundingClientRect().top || 0,
    bottom: navbar?.getBoundingClientRect().bottom || 0,
    left: navbar?.getBoundingClientRect().left || 0,
    right: navbar?.getBoundingClientRect().right || 0,
    isHidden: navbar?.classList.contains('navbar-hide'),
    height: navbar?.getBoundingClientRect().height || 0,
  };
}
