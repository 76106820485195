$(() => {
  const modalConfirm = (callback) => {
    $('#btn-confirm').on('click', () => {
      $('#reportModal').modal('show');
    });

    $('button.report-save-btn').on('click', () => {
      callback(true);
      $('#reportModal').modal('hide');
    });

    $('#modal-btn-no').on('click', () => {
      callback(false);
      $('#reportModal').modal('hide');
    });
  };

  modalConfirm((confirm) => {
    if (confirm) {
      //
    } else {
      //
    }
  });

  const operators = {
    '+': (a, b) => a + b,
    '-': (a, b) => a - b,
  };

  function toInt(int) {
    return parseInt(int.toString().replace('.', ''), 10);
  }
  function toHour(minutes) {
    const hours = toInt(minutes) / 60;
    return parseFloat(hours.toFixed(2)).toLocaleString('de-DE');
  }

  // set all checkbox
  $('.select-all').prop('checked', true);

  // initial minutes
  const minutesByRole = {};
  const totalMinutesByRole = {};

  /**
  * A function to update total hours tables
  */
  function updateTotalMinutesTable() {
    $('.total-hours-project tr').each((i, elem) => {
      const $tr = $(elem);
      $tr.find('td.hour').html(toHour(minutesByRole[$(elem).data('role')]));
    });

    $('.total-project').html(`
      ${toHour(minutesByRole.total)} HH
    `);
  }

  /**
  * A function to set minutesByRole to the default value
  */
  function selectAllIssues() {
    // update the select with parent issues
    $('select[name="parent_issues"] option').prop('selected', true);

    minutesByRole.total = totalMinutesByRole.total;

    $('.total-hours-project tr').each((i, elem) => {
      const role = $(elem).data('role');
      minutesByRole[role] = totalMinutesByRole[role];
    });

    updateTotalMinutesTable();
  }

  /**
  * A function to set minutesByRole to 0
  */
  function selectNoIssues() {
    $('select[name="parent_issues"] option').prop('selected', false);

    minutesByRole.total = 0;

    $('.total-hours-project tr').each((i, elem) => {
      minutesByRole[$(elem).data('role')] = 0;
    });

    updateTotalMinutesTable();
  }

  // set minutesByRole and totalMinutesByRole
  minutesByRole.total = 0;
  totalMinutesByRole.total = 0;

  $('.total-hours-project tr').each((i, elem) => {
    minutesByRole[$(elem).data('role')] = 0;
    totalMinutesByRole[$(elem).data('role')] = 0;
  });

  // initialize datatable
  const table = $('table.jira-table').DataTable({
    fixedHeader: true,
    language: {
      decimal: ',',
      thousands: '.'
    },
    columnDefs: [
      { orderable: false, targets: 0 }
    ],
    order: [[1, 'asc']],
    pageLength: 100
  });

  const tableRows = table.rows().nodes();

  // Calculate the total amount of minutes
  $('.issue-checkbox', tableRows).each((index, inputElem) => {
    const $parent = $(
      table
        .rows(`.parent-issue[data-issue="${$(inputElem).data('issue')}"]`)
        .nodes()
    );
    const $values = $parent.find('.hours-by-role dl dt');
    $values.each((i, hoursElem) => {
      const role = $(hoursElem).data('role');
      const minutes = $(hoursElem).data('minutes');
      if (inputElem.checked) {
        minutesByRole[role] += minutes;
        minutesByRole.total += minutes;
      }
      totalMinutesByRole[role] += minutes;
      totalMinutesByRole.total += minutes;
    });
  });

  updateTotalMinutesTable();

  /*
   * When an issue is checked or un-checked, update the total hours tables
   */
  $('.issue-checkbox', tableRows).on('change', (e) => {
    const $this = $(e.currentTarget);
    const $row = $this.closest('tr');
    const $values = $row.find('.hours-by-role dl dt');

    const operator = ((e.currentTarget.checked) ? '+' : '-');

    // update the select with parent issues
    $(`select[name="parent_issues"] option[value="${$(e.currentTarget).data('issue')}"]`)
      .prop('selected', e.currentTarget.checked);

    // for each row with values,
    $values.each((i, elem) => {
      const role = $(elem).data('role');
      minutesByRole[role] = operators[operator](
        minutesByRole[role],
        $(elem).data('minutes')
      );

      minutesByRole.total = operators[operator](
        minutesByRole.total,
        $(elem).data('minutes')
      );
    });

    updateTotalMinutesTable();
  });

  // Check/uncheck all checkboxes in the table
  $('.select-all').on('click', (e) => {
    const rows = table.rows({ search: 'applied' }).nodes();
    let $checkboxes;

    if (e.currentTarget.checked) {
      $checkboxes = $('input.issue-checkbox[type="checkbox"]', rows)
        .not(':checked');
    } else {
      $checkboxes = $('input.issue-checkbox[type="checkbox"]:checked', rows);
    }
    $checkboxes.prop('checked', e.currentTarget.checked);

    if (e.currentTarget.checked) {
      selectAllIssues();
    } else {
      selectNoIssues();
    }
  });

  $('#fake_show_subtasks').on('change', (e) => {
    const $checkBox = $('#id_show_subtasks');
    $checkBox.prop('checked', $(e.currentTarget).is(':checked'));
  });

  $('table.jira-table tbody').on('click', '.details-control', (e) => {
    const $tr = $(e.currentTarget).parents('tr');
    const row = table.row($tr);
    const issueId = $tr.data('issue');
    const $detailRow = $(`.detail-row[data-issue="${issueId}"]`);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
      $tr.removeClass('shown');
      $(e.currentTarget).removeClass('show');

      row.child().find('.update-role-select').select2('destroy');
      row.child().find('.update-role-select').removeAttr('data-select2-id');
    } else {
      // Open this row
      row.child($detailRow.html()).show();
      $tr.addClass('shown');
      $(e.currentTarget).addClass('show');

      row.child().find('.update-role-select').select2();
      row.child().find('.update-role-select').on('change', (rowChangeEvent) => {
        const $select = $(rowChangeEvent.currentTarget);
        const newRole = $select.val();
        const url = $select.data('url');
        const oldRole = $select.parent().parent().parent().attr('data-role');
        const subtaskId = $select.data('issue');
        const csrf = $('[name=csrfmiddlewaretoken]').val();

        $.post(
          url,
          {
            from_role: oldRole,
            to_role: newRole,
            csrfmiddlewaretoken: csrf
          },
          (data) => {
            // jira project
            if ($tr.find('.issue-checkbox').is(':checked')) {
              data.jira_project.forEach((elem) => {
                const role = elem[1];
                const minutes = elem[2];

                minutesByRole[role] = minutes;

                const $jiraProjectRole = $(`
                    .total-hours-project tr[data-role="${elem[1]}"]
                `);
                $jiraProjectRole
                  .attr('data-minutes', toInt(minutesByRole[role]));
                $jiraProjectRole
                  .find('td.hour')
                  .html(toHour(minutesByRole[role]));
              });
            }
            // issue
            const $issueRole = $(`
                tr[data-issue="${subtaskId}"]
                li[data-role="${oldRole}"]
                .hours-by-role
            `);
            $issueRole.attr('data-minutes', toInt(data.issue[0].minutes));
            $issueRole.html(`
              ${data.issue[0].name}: ${toHour(data.issue[0].minutes)}
            `);

            // select
            $detailRow
              .find(`tr[data-issue="${subtaskId}"] li[data-role="${oldRole}"]`)
              .find('.update-role-select option')
              .removeAttr('selected');

            $detailRow
              .find(`tr[data-issue="${subtaskId}"] li[data-role="${oldRole}"]`)
              .find(`.update-role-select option[value="${data.issue[0].code}"]`)
              .attr('selected', 'selected');

            $(`tr[data-issue="${subtaskId}"]`)
              .find(`li[data-role="${data.issue[0].code}"]`)
              .remove();
            $(`tr[data-issue="${subtaskId}"]`)
              .find(`li[data-role="${oldRole}"]`)
              .attr('data-role', data.issue[0].code);

            // parent issue
            if (data.parent_issue_id) {
              let hoursByRoleParentIssue = '';
              hoursByRoleParentIssue += `
                <dl style="padding: 0; list-style-type: none;">
              `;
              data.parent_issue.forEach((elem) => {
                hoursByRoleParentIssue += `
                  <dt data-role="${elem.code}"
                  data-minutes="${toInt(elem.minutes)}">${elem.name}</dt>
                  <dd>${toHour(elem.minutes)}</dd>
                `;
              });
              hoursByRoleParentIssue += '</dl>';
              const $parentIssue = $(`
                .parent-issue[data-issue="${data.parent_issue_id}"]
              `);
              $parentIssue.find('.hours-by-role').html(hoursByRoleParentIssue);
            }
          }
        );
      });
    }
  });

  $('button.report-save-btn, button.report-pdf, button.report-csv').on('click', (e) => {
    const $form = $('form.filter-form');
    const saveURL = $(e.currentTarget).data('save-url');
    $form.attr('method', 'post');
    $form.attr('action', saveURL);
    $form.trigger('submit');
  });

  $('.filter-button').on('click', (e) => {
    e.preventDefault();
    const $form = $('.filter-form');
    $form.attr('action', '');
    $form.trigger('submit');
  });
});
