import React from 'react';
import Select from 'react-select';

export type CustomSelectOption = {
  label: string,
  value: number,
};

export interface CustomSelectProps {
  defaultValue: CustomSelectOption;
  options: CustomSelectOption[];
  onChange: (selectedOption: CustomSelectOption | null) => void;
  inputId?: string
}

export function CustomSelect({
  defaultValue, options, onChange, inputId = ''
}: CustomSelectProps) {
  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      inputId={inputId}
      styles={{
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: 0
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          margin: 0
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          margin: 0,
          padding: 0
        })
      }}
      classNames={{
        control: () => 'form-select',
        dropdownIndicator: () => 'd-none',
        indicatorsContainer: () => 'd-none'
      }}
      onChange={onChange}
    />
  );
}
