/**
 * Returns a given date in a ISO-8601 string format: yyyy-mm-dd.
 * Unlike .toJSON().slice(0,10) or similar methods, it keeps the date
 * in the local timezone.
 */
export function dateToStr(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const isoString = `${year}-${month}-${day}`;
  return isoString;
}

/**
 * Returns the date (in local timezone) of the monday of the week to
 * which the given date belongs (assuming iso-weeks, from monday to sunday).
 */
export function getStartOfWeek(date: Date) {
  const dayOfWeek = date.getDay();
  const baseDate = new Date(date);
  baseDate.setHours(0);
  baseDate.setMinutes(0);
  baseDate.setSeconds(0);
  baseDate.setMilliseconds(0);

  // sunday is 0, monday is 1, adjust for monday:
  const newDay = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  const monday = new Date(baseDate.setDate(newDay));
  return monday;
}

/**
 * Returns the date (in local timezone) of the monday of the week following
 * the week to which the given date belongs (assuming iso-weeks, from monday to sunday).
 */
export function getNextWeek(date: Date) {
  const thisWeek = getStartOfWeek(date);
  const newDay = thisWeek.getDate() + 7;
  return new Date(thisWeek.setDate(newDay));
}

/**
 * Returns the date (in local timezone) of the monday of the week prevoius to
 * the week to which the given date belongs (assuming iso-weeks, from monday to sunday).
 */
export function getPreviousWeek(date: Date) {
  const thisWeek = getStartOfWeek(date);
  const newDay = thisWeek.getDate() - 7;
  return new Date(thisWeek.setDate(newDay));
}

/**
 * Returns the date (in local timezone) of the day previous to the
 * given day, keeping the same time (like substracting 24 hours).
 */
export function getPreviousDay(date: Date) {
  const thisDay = new Date(date);
  const newDay = thisDay.getDate() - 1;
  return new Date(thisDay.setDate(newDay));
}
