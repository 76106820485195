import React, { useEffect, useState } from 'react';
import { useNavbarPosition } from '../../../hooks/navbarPosition';

type StickyElementRef = HTMLDivElement | null;

/**
 * @param floatingElementRef - react ref of the floating element
 * @param intersectionRef - react ref of the element that is used in the useIntersection hook
 * @returns boolean - True if the floating element position surpasses its container
 */

export interface StickyTopElementProps {
  children: React.ReactNode
  isParentScrolling: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StickyTopElement({ children, isParentScrolling = false }: StickyTopElementProps) {
  const intersectionRef = React.useRef<StickyElementRef>(null);
  const stickyElement = React.useRef<StickyElementRef>(null);
  const { isHidden, height } = useNavbarPosition();
  const [
    initialIntersectionElementLeft,
    setInitialIntersectionElementLeft
  ] = useState<number | null>(null);

  const intersectionElementPosition = intersectionRef?.current?.getBoundingClientRect();
  const stickyElementPosition = stickyElement?.current?.getBoundingClientRect();

  const ennableSticky = intersectionElementPosition
    ? intersectionElementPosition.top < 0
    : false;

  const fixedElementParentStyles: React.CSSProperties = {
    position: 'fixed',
    top: !isHidden ? height : 0,
    zIndex: 2,
    transition: 'top 0.5s ease',
    overflow: 'hidden'
  };

  const fixedElementStyles: React.CSSProperties = {
    position: 'relative',
    top: 0,
  };
  if (intersectionElementPosition && initialIntersectionElementLeft) {
    fixedElementStyles.left = intersectionElementPosition.left - initialIntersectionElementLeft;
  }

  const placeholderElementStyles: React.CSSProperties = {
    height: stickyElement.current?.getBoundingClientRect().height || 0
  };

  useEffect(() => {
    if (stickyElementPosition && initialIntersectionElementLeft === null) {
      setInitialIntersectionElementLeft(stickyElementPosition.left);
    }
  }, [stickyElementPosition]);

  return (
    <>
      <div ref={intersectionRef} />
      {/* Placeholder element to avoid layout shift when the sticky is active */}
      <div style={ennableSticky ? placeholderElementStyles : {}} />
      <div
        style={ennableSticky ? fixedElementParentStyles : {}}
      >
        <div
          ref={stickyElement}
          className="bg-white"
          style={ennableSticky ? fixedElementStyles : {}}
        >
          {children}
        </div>
      </div>
    </>
  );
}
