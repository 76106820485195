import React from 'react';
import { dateToStr } from '../../../utils/date-utils';

export type WeekSelectorProps = {
  startDate: Date,
  endDate: Date,
  goToNextWeek: () => void,
  goToPreviousWeek: () => void
};

/**
 * Renders a week selector that can go forward or backward one week.
 */
export function WeekSelector(props: WeekSelectorProps) {
  const {
    startDate, endDate, goToNextWeek, goToPreviousWeek
  } = props;

  return (
    <div className="input-group input-group-sm d-inline-flex w-auto mb-3 mx-2">
      <button type="button" className="btn btn-outline-primary btn-sm" onClick={goToPreviousWeek}>
        <i className="fad fa-chevron-left" />
      </button>
      <span className="input-group-text">
        <b className="me-2">{dateToStr(startDate)}</b>
        to
        <b className="ms-2">{dateToStr(endDate)}</b>
      </span>
      <button type="button" className="btn btn-outline-primary btn-sm" onClick={goToNextWeek}>
        <i className="fad fa-chevron-right" />
      </button>
    </div>
  );
}
