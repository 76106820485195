import { TimeResolution } from './planner-data.interface';

/**
 * Converts a Date object into 'yyyy-mm-dd' representation.
 */
export const dateStr = (date: Date): string => date.toJSON().substring(0, 10);

/**
* Returns an array of Date objects between two dates.
* @param minDate start date in 'yyyy-mm-dd' format.
* @param maxDate end date in 'yyyy-mm-dd' format.
* @param timeResolution the needed range format. DAILY to get all days, or
*        WEEKLY to get the week start date. Defaults to DAILY
*/
export const getDatesRange = (
  minDate: string,
  maxDate: string,
  timeResolution?: TimeResolution
): Date[] => {
  const dates: Date[] = [];
  const startDate = new Date(minDate);
  const endDate = new Date(maxDate);
  const range = timeResolution === 'WEEKLY' ? 7 : 1;
  // Cahnges in daylight saving time are far enough from 12:00
  startDate.setUTCHours(12);
  endDate.setUTCHours(12);

  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + range)) {
    dates.push(new Date(date));
  }
  return dates;
};

/**
 * Returns the month name of a given date, based on the browser language.
 */
export const monthName = (date: Date): string => date.toLocaleString('default', { month: 'long' });

/**
 * Format float numbers to fixed point notation
 * @param float - The number to format
 * @param fractionDigits  - The amount of decimals to keep
 * @returns float formatted value
 */
export const floatFormat = (float: number, fractionDigits = 1): number => (
  Number(float.toFixed(fractionDigits))
);
