$(() => {
  function getSaleGoalFields(element) {
    const form = $(element).closest('.salegoal-form');
    return {
      descriptionField: $(form).find('.description-field').find('input'),
      assignedHorusField: $(form).find('.assigned_hours-field').find('input'),
      estimatedHoursField: $(form).find('.estimated_hours-field').find('input'),
      consumedHoursField: $(form).find('.consumed_hours-field').find('input'),
    };
  }

  function toggleFormButtons(element, formFields) {
    const form = $(element).closest('.salegoal-form');
    const controlButtons = $(form).find('.submit-field').find('.btn-group');
    const editButton = $(form).find('.submit-field').find('.btn-edit');
    const state = editButton.is(':hidden');
    $(editButton).prop('hidden', !state);
    $(controlButtons).prop('hidden', state);
    Object.values(formFields).forEach((input) => {
      input.prop('disabled', state);
    });
  }

  $('button.form-enabler').on('click', (e) => {
    const formFields = getSaleGoalFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
  });

  function refreshTotals(response) {
    $('#totalAssigned').html(response.assigned_hours.toString().concat(' HH'));
    $('#totalEstimated').html(response.estimated_hours.toString().concat(' HH'));
    $('#totalProgress').html(response.average_progress.toString().concat('%'));
    $('#totalConsumed').html(response.consumed_hours.toString().concat(' HH'));
    $('#totalTotalHours').html(response.total_hours.toString().concat(' HH'));
    if (response.exceed_hours) {
      $('.alert-warning').show('fade');
      setTimeout(() => {
        $('.alert-warning').hide('fade');
      }, 5000);
    }
  }

  $('button.btn-submit-salegoal').on('click', (e) => {
    const submitButton = e.currentTarget;
    const formFields = getSaleGoalFields(submitButton);
    const form = $(submitButton).closest('.salegoal-form');
    const data = {
      csrfmiddlewaretoken: $('[name=csrfmiddlewaretoken]').val(),
      salegoal_id: submitButton.id,
      description: $(formFields.descriptionField).val(),
      assigned_hours: $(formFields.assignedHorusField).val(),
      estimated_hours: $(formFields.estimatedHoursField).val(),
      consumed_hours: $(formFields.consumedHoursField).val(),
    };
    $.ajax({
      url: submitButton.dataset.url,
      type: 'POST',
      data,
      success(result) {
        $('.alert-success').show('fade');
        $(submitButton).removeClass('btn-danger');
        $(submitButton).addClass('btn-outline-info');
        toggleFormButtons(submitButton, formFields);
        refreshTotals(result);
        formFields.descriptionField.data('last-save', data.description);
        formFields.assignedHorusField.data('last-save', data.assigned_hours);
        formFields.estimatedHoursField.data('last-save', data.estimated_hours);
        formFields.consumedHoursField.data('last-save', data.consumed_hours);
        $(form).find('.total_hours-field').find('input').val(result.salegoal_total);
        $(form).find('.progress-field').find('input').val(result.salegoal_progress);
        setTimeout(() => {
          $('.alert-success').hide('fade');
        }, 3000);
      },
      error(result) {
        $('.alert-danger').text(result.responseText);
        $('.alert-danger').show('fade');
        $(submitButton).removeClass('btn-outline-info');
        $(submitButton).addClass('btn-danger');
        setTimeout(() => {
          $('.alert-danger').hide('fade');
        }, 4000);
      }
    });
  });
  $('button.btn-cancel').on('click', (e) => {
    const formFields = getSaleGoalFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
    formFields.descriptionField.val(formFields.descriptionField.data('last-save')).trigger('change');
    formFields.assignedHorusField.val(formFields.assignedHorusField.data('last-save')).trigger('change');
    formFields.estimatedHoursField.val(formFields.estimatedHoursField.data('last-save')).trigger('change');
    formFields.progressField.val(formFields.progressField.data('last-save')).trigger('change');
    formFields.consumedHoursField.val(formFields.consumedHoursField.data('last-save')).trigger('change');
  });
});
