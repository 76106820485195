$(() => {
  $('#id_start_date').on('change.datetimepicker', () => {
    const startDate = $('input#id_start_date').val();
    const endDate = $('input#id_end_date').val();

    // Minimum end date is the start date
    if (startDate) {
      $('#id_end_date').datetimepicker('minDate', moment(startDate, 'DD/MM/YYYY'));
      $('#id_end_date').val(endDate);
    }

    // If start date > end date, then make end date = start date
    if (endDate && moment(startDate, 'DD/MM/YYYY').isAfter(moment(endDate, 'DD/MM/YYYY'))) {
      $('#id_end_date').val(startDate);
    }
  });
});
