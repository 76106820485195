function updateQuotationSummaryData(quotationId) {
  const url = `/organizations/quotations/${quotationId}/summary/`;
  $.ajax({
    url,
    method: 'GET',
    success: (rawData) => {
      $('#quotation-summary').html(rawData);
    },
    error: () => {
      $('#quotation-summary').html('Ha ocurrido un error');
    }
  });
}

$(() => {
  const $quotationSelect = $('#id_quotation');
  $quotationSelect.parent().parent().append('<div id="quotation-summary"></div>');

  const initialQuotationId = $quotationSelect.val();
  if (initialQuotationId) {
    updateQuotationSummaryData(initialQuotationId);
  }

  $quotationSelect.on('change', () => {
    const quotationId = $quotationSelect.val();
    updateQuotationSummaryData(quotationId);
  });
});
