import React from 'react';
import { Action } from '../../../planner-data.interface';

export interface ButtonsProps {
  dispatch: React.Dispatch<Action>;
}

function ButtonsComponent({ dispatch }: ButtonsProps) {
  return (
    <div className="btn-group d-block">
      <button type="button" className="btn btn-sm btn-secondary" onClick={() => { dispatch({ type: 'closePopup' }); }}>
        <i className="fas fa-times-circle" />
      </button>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => { dispatch({ type: 'showUpdate' }); }}>
        <i className="fas fa-pen" />
      </button>
      <button type="button" className="btn btn-sm btn-danger" onClick={() => { dispatch({ type: 'showDelete' }); }}>
        <i className="fas fa-trash" />
      </button>
    </div>
  );
}

export const Buttons = React.memo(ButtonsComponent);
