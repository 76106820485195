import React, { useState } from 'react';
import { Action } from '../../../planner-data.interface';

export interface EditFormProps {
  dispatch: React.Dispatch<Action>;
  defaultValue?: number;
}

function EditFormComponent({ dispatch, defaultValue = 0 }: EditFormProps) {
  const [hours, setHours] = useState(defaultValue);
  const [updateEnabled, setUpdateEnabled] = useState(false);

  return (
    <div className="border p-3">
      <div className="mb-3">
        <label htmlFor="popup-hours-input">
          Hours per day:
          <input
            /*
              We can use autoFocus here because the form has only one input, so it doesn't cause an
              accessibility problem.
            */
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            type="number"
            step="0.5"
            min="0"
            max="8"
            id="popup-hours-input"
            className="form-control"
            value={hours}
            onChange={(e) => {
              const n = Number(e.target.value);
              setHours(n);
              setUpdateEnabled(n > 0);
            }}
          />
        </label>
      </div>
      <div className="form-check mb-3">
        <label className="form-check-label" htmlFor="popup-available-time-input">
          <input type="checkbox" className="form-check-input" id="popup-available-time-input" />
          Only available time
        </label>
      </div>
      <button type="button" className="btn btn-secondary" onClick={() => { dispatch({ type: 'closePopup' }); }}>Cancel</button>
      <button type="button" className="btn btn-primary ms-3" disabled={!updateEnabled} onClick={() => dispatch({ type: 'updateSelection', hours })}>Update</button>
    </div>
  );
}

export const EditForm = React.memo(EditFormComponent);
