$('#id_quotation').on('change', (event) => {
  const quotationId = $(event.currentTarget).val();
  const url = `/api/v1/quotations/${quotationId}/`;
  $.ajax({
    url,
    method: 'GET',
    dataType: 'json',
    success: (rawData) => {
      $('#id_price_clf').val(rawData.approvedPrice);
    },
    error: () => {
      $('#id_price_clf').val('');
    }
  });
});
