$(() => {
  let previousScrollTop;
  const $window = $(window);

  function shouldHideNavbar() {
    const scrollTop = $window.scrollTop();

    if (scrollTop < 100) {
      return false;
    }

    const scrollDelta = scrollTop - previousScrollTop;

    previousScrollTop = scrollTop;

    if (scrollDelta < 0) {
      return false;
    }

    return true;
  }

  // scroll functions
  $(window).on('scroll', () => {
    // add/remove class to navbar when scrolling to hide/show
    $('.navbar')[
      shouldHideNavbar() ? 'addClass' : 'removeClass'
    ]('navbar-hide');
  });
});
