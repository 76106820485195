import React from 'react';

export interface CellProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

function CellComponent({
  style = undefined,
  children = undefined,
}: CellProps) {
  return (
    <div className="planner-cell border-end user-select-none" style={{ ...style }}>
      {children}
    </div>
  );
}

export const Cell = React.memo(CellComponent);
