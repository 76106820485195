import React from 'react';

const dayName = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export interface DateHeaderProps {
  date: Date;
  disabledDays: string[];
}

function DateHeaderComponent({ date, disabledDays }: DateHeaderProps) {
  const isDisabledDate = disabledDays.includes(date.toISOString().split('T')[0]);

  return (
    <div className={`date-header ${isDisabledDate ? 'disabled' : ''}`}>
      <span className="fw-bold">
        {date.toJSON().substring(8, 10)}
      </span>
      <span>
        {dayName[date.getDay()]}
      </span>
    </div>
  );
}

export const DateHeader = React.memo(DateHeaderComponent);
