$(() => {
  function getContactFields(element) {
    const form = $(element).closest('.contact-form');
    return {
      relationField: $(form).find('.relation-field').find('input'),
      profilingField: $(form).find('.profiling-field').find('input')
    };
  }

  $('#alertClose').on('click', () => {
    $('.alert').hide('fade');
  });

  function toggleFormButtons(element, formFields) {
    const form = $(element).closest('.contact-form');
    const controlButtons = $(form).find('.submit-field').find('.btn-group');
    const editButton = $(form).find('.submit-field').find('.btn-edit');
    const state = editButton.is(':hidden');
    $(editButton).prop('hidden', !state);
    $(controlButtons).prop('hidden', state);
    Object.keys(formFields).forEach((field) => {
      $(formFields[field]).prop('disabled', state);
    });
  }

  $('button.form-enabler').on('click', (e) => {
    const formFields = getContactFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
  });

  $('button.btn-submit-contact').on('click', (e) => {
    const submitButton = e.currentTarget;
    const formFields = getContactFields(e.currentTarget);
    const data = {
      csrfmiddlewaretoken: $('[name=csrfmiddlewaretoken]').val(),
      contact_id: submitButton.id,
      name: $(formFields.nameField).val(),
      relation: $(formFields.relationField).val(),
      email: $(formFields.emailField).val(),
      phone_number: $(formFields.phoneField).val()
    };
    $.ajax({
      url: submitButton.dataset.url,
      type: 'POST',
      data,
      success() {
        $('.alert-success').show('fade');
        $(submitButton).removeClass('btn-danger');
        $(submitButton).addClass('btn-outline-info');
        toggleFormButtons(submitButton, formFields);
        formFields.relationField.data('last-save', data.relation);
        setTimeout(() => {
          $('.alert-success').hide('fade');
        }, 3000);
      },
      error(result) {
        $('.alert-danger').text(result.responseText);
        $('.alert-danger').show('fade');
        $(submitButton).removeClass('btn-outline-info');
        $(submitButton).addClass('btn-danger');
        setTimeout(() => {
          $('.alert-danger').hide('fade');
        }, 4000);
      }
    });
  });
  $('button.btn-cancel').on('click', (e) => {
    const formFields = getContactFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
    formFields.nameField.val(formFields.nameField.data('last-save')).trigger('change');
    formFields.relationField.val(formFields.relationField.data('last-save')).trigger('change');
    formFields.emailField.val(formFields.emailField.data('last-save')).trigger('change');
    formFields.phoneField.val(formFields.phoneField.data('last-save')).trigger('change');
  });
});
