$(() => {
  const idQuotationInput = $('#id_quotation');
  let quotationId = idQuotationInput.val();
  const planningFile = $('#id_planning_file');

  if (!planningFile) {
    return;
  }

  planningFile.parent().append(
    '<div id="id_quotation_tooltip">Quotation has daily plannings: planning file disabled</div>'
  );
  disablePlanningFileIfPlannings(planningFile, quotationId);

  idQuotationInput.on('change', (event) => {
    quotationId = $(event.currentTarget).val();
    planningFile.val(null);
    disablePlanningFileIfPlannings(planningFile, quotationId);
  });
});

function checkPlanningFile(quotationId) {
  const url = `/api/v1/quotations/${quotationId}/`;
  let returnValue = false;
  if (!quotationId) {
    return false;
  }
  $.ajax({
    url,
    method: 'GET',
    success: (response) => { returnValue = response.hasDailyPlannings; },
    error: () => {
      console.error('Error fetching data from quotation: ', quotationId);
    }
  });
  return returnValue;
}

function disablePlanningFileIfPlannings(planningFile, quotationId) {
  const disabled = checkPlanningFile(quotationId);
  planningFile.prop('disabled', disabled);
  $('#id_quotation_tooltip').prop('hidden', !disabled);
}
