import React from 'react';
import { CompletionBar } from './completion-bar';
import { ReportingResource } from '../types';

type ResourcesTableProps = {
  title: string,
  resources: ReportingResource[] | undefined
};

/**
 * Assign a color for each report level.
 * See the execution-report.scss for more details.
 */
function getReportClass(reportRate: number) {
  if (reportRate >= 39 / 40) {
    return 'report-rate-perfect';
  } if (reportRate >= 38 / 40) {
    return 'report-rate-good';
  } if (reportRate >= 36 / 40) {
    return 'report-rate-low';
  }
  return 'report-rate-failed';
}

/**
 * Renders a table with the resource name and the reported vs planned hours for each one.
 * If the report data is not loaded yet, it shows a loading spinner.
 */
export function ResourcesTable(props: ResourcesTableProps) {
  return (
    <div className="card execution-report">
      <div className="card-header">
        <h3 className="my-0">{props.title}</h3>
      </div>
      <div className="card-body">
        { props.resources
          ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Reported</th>
                  <th>Reported percentage</th>
                </tr>
              </thead>
              <tbody>
                {props.resources.map((resource) => (
                  <tr key={resource.name} className={getReportClass(resource.reportRate)}>
                    <td className="align-middle">
                      {resource.imageUrl && <img src={resource.imageUrl} alt={resource.name} className="thumbnail" />}
                      {resource.name}
                    </td>
                    <td className="align-middle">
                      {Math.round(10 * resource.reportedHours) / 10}
                      {'h of '}
                      {resource.plannedHours}
                      h
                    </td>
                    <td className="align-middle">
                      <CompletionBar title="Reported percentage" value={resource.reportRate} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
          : (
            <>
              <i className="fa fa-spinner fa-spin me-2" />
              Loading report...
            </>
          )}
      </div>
    </div>
  );
}
