$(() => {
  function getSetupTaskFields(element) {
    const form = $(element).closest('.setup-task-form');
    return {
      doneField: $(form).find('.done-field').find('input'),
      userField: $(form).find('.user-field').find('select'),
      commentField: $(form).find('.comment-field').find('textarea')
    };
  }

  function toggleFormButtons(element, formFields) {
    const form = $(element).closest('.setup-task-form');
    const controlButtons = $(form).find('.submit-field').find('.btn-group');
    const editButton = $(form).find('.submit-field').find('.btn-edit');
    const state = editButton.is(':hidden');
    $(editButton).prop('hidden', !state);
    $(controlButtons).prop('hidden', state);
    Object.values(formFields).forEach((input) => {
      input.prop('disabled', state);
    });
  }

  $('button.form-enabler').on('click', (e) => {
    const formFields = getSetupTaskFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
  });

  $('button.btn-submit-stagesetup').on('click', (e) => {
    const submitButton = e.currentTarget;
    const formFields = getSetupTaskFields(e.currentTarget);
    const data = {
      csrfmiddlewaretoken: $('[name=csrfmiddlewaretoken]').val(),
      task_id: submitButton.id,
      done: $(formFields.doneField).is(':checked'),
      user: $(formFields.userField).val(),
      comment: $(formFields.commentField).val()
    };
    $.ajax({
      url: submitButton.dataset.url,
      type: 'POST',
      data,
      success() {
        $('.alert-success').show('fade');
        $(submitButton).removeClass('btn-danger');
        $(submitButton).addClass('btn-outline-info');
        toggleFormButtons(submitButton, formFields);
        formFields.doneField.data('last-save', data.done);
        formFields.userField.data('last-save', data.user_id);
        formFields.commentField.data('last-save', data.comment);
        setTimeout(() => {
          $('.alert-success').hide('fade');
        }, 3000);
      },
      error(result) {
        $('.alert-danger').text(result.responseText);
        $('.alert-danger').show('fade');
        $(submitButton).removeClass('btn-outline-info');
        $(submitButton).addClass('btn-danger');
        setTimeout(() => {
          $('.alert-danger').hide('fade');
        }, 4000);
      }
    });
  });

  $('button.btn-cancel').on('click', (e) => {
    const formFields = getSetupTaskFields(e.currentTarget);
    toggleFormButtons(e.currentTarget, formFields);
    formFields.doneField.prop('checked', formFields.doneField.data('last-save'));
    formFields.userField.val(formFields.userField.data('last-save')).trigger('change');
    formFields.commentField.val(formFields.commentField.data('last-save')).trigger('change');
  });
});
