$(() => {
  const targetElement = document.querySelector('#QuotationsTable > thead > tr');

  const headerFields = {
    Name: 'name',
    Version: 'version',
    'Created at': 'created_at',
    'Created by': 'created_by__name',
    Organization: 'account__organization__name',
    Account: 'account__name',
    'Contract type': 'approved_contract_type__name',
    Hours: 'approved_hours',
    'Total cost': 'approved_total_cost',
    Price: 'approved_price',
    'Operational approval': 'approved_by__name',
    'Commercial approval': 'commercial_approved_by__name',
    'Approved date': 'approved_at'
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let field = urlParams.get('order_field') || '-created_at';
  let direction = '-';

  if (field[0] === '-') {
    field = field.substring(1);
  } else {
    direction = '';
  }

  function handleClick(e) {
    if (field !== headerFields[e.target.textContent.trim()]) {
      field = headerFields[e.target.textContent.trim()];
      direction = '';
    } else {
      direction = direction === '' ? '-' : '';
    }

    urlParams.set('order_field', `${encodeURIComponent(direction)}${encodeURIComponent(field)}`);
    window.location.search = urlParams;
  }

  if (targetElement) {
    Array.from(targetElement.children).forEach((child) => {
      $(child).off();
      const childName = child.textContent.trim();
      const childField = headerFields[childName];

      if (childField === field) {
        child.classList.remove('sorting', 'sorting_asc', 'sorting_desc');

        if (direction === '') {
          child.classList.add('sorting_asc');
          child.setAttribute('aria-sort', 'ascending');
        } else {
          child.classList.add('sorting_desc');
          child.setAttribute('aria-sort', 'descending');
        }
      }

      child.addEventListener('click', handleClick);
    });
  } else {
    console.log('Element not found');
  }
});
