import { App } from '../utils/app';

$(() => {
  $('.model-form input:text').addClass('form-control');

  $('select').not('.js-not-select2').select2({
    theme: 'bootstrap-5'
  });

  // Initialize multiple select2
  const SELECT2_COUNTER_CONTAINER_SELECTOR = '.select2-selection';
  const SELECT2_COUNTER_CLASS = 'select2__counter';
  const SELECT2_COUNTER_SELECTOR = `.${SELECT2_COUNTER_CLASS}`;
  const $counterTemplate = $(`<span class="${SELECT2_COUNTER_CLASS} ms-2">1</span>`);

  $('select[multiple]:not(.js-not-select2)').each(() => {
    const $this = $(this);
    $this.select2({
      closeOnSelect: false,
      theme: 'bootstrap-5',
      placeholder: $this.data('placeholder')
    })
      .on('change', function updateCounter() {
        const $counter = $(this).next().find(SELECT2_COUNTER_SELECTOR);
        const numberOfSelectedItems = $(this).select2('data').length;

        if (numberOfSelectedItems < 1) {
        // Hide counter
          $counter.addClass('d-none');
        } else if (numberOfSelectedItems === 1) {
        // Show counter
          $counter.removeClass('d-none');
        }

        // Update counter
        $counter.text(numberOfSelectedItems);
      })
      .each((_, select) => {
      // Create counter with the number of selected items
        const $counter = $counterTemplate;
        const numberOfSelectedItems = $(select).select2('data').length;

        $counter.text(numberOfSelectedItems);

        // Hide if select has zero items selected
        if (numberOfSelectedItems < 1) {
          $counter.addClass('d-none');
        }

        // Append counter to select2 element
        $(select)
          .next()
          .find(SELECT2_COUNTER_CONTAINER_SELECTOR)
          .append($counter);
      });
  });

  document.querySelectorAll('form')
    .forEach((form) => {
      form.addEventListener('submit', () => {
        const submitButtons = [...form.elements].filter((element) => (
          element.matches('[type="submit"]:not(.js-do-not-disable-on-submit)')
        ));

        // Disable buttons after submit to prevent disabling submit inputs
        // with values
        submitButtons.forEach((submitButton) => {
          // eslint-disable-next-line no-param-reassign
          (submitButton/* as HTMLButtonElement */).disabled = true;
          App.Utils.showLoading(submitButton);
        });

        return true;
      });
    });
});
