$(() => {
  const toggleSubRowsBtnSelector = '.toggle-sub-rows-btn';
  const ganttSubRowSelector = '.gantt-sub-row';

  function toggleSubRows() {
    $(ganttSubRowSelector).toggle();
    $(toggleSubRowsBtnSelector).find('svg,.fa')
      .toggleClass('fa-chevron-right')
      .toggleClass('fa-chevron-down');
  }

  $(toggleSubRowsBtnSelector).on('click', toggleSubRows);
});
