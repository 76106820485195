$(() => {
  const usersDropdown = $('#id_users');
  const planningDropdown = $('#id_planning_mode');
  const toggleUsersDropdown = (event) => {
    usersDropdown.parents('.row').toggle(event.target.value === 'group');
  };
  planningDropdown.on('change', toggleUsersDropdown);

  if (planningDropdown.val()) {
    usersDropdown.parents('.row').toggle(planningDropdown.val() === 'group');
  }
});
