import React, { useState } from 'react';
import Select from 'react-select';
import { Action, ResourceInGroup } from '../../../planner-data.interface';
import { CustomSelect, CustomSelectOption } from '../../../../common/CustomSelect';

export interface CreateFormProps {
  dispatch: React.Dispatch<Action>;
  resources: ResourceInGroup[];
}

const SELECT_PLACEHOLDER_OPTION: CustomSelectOption = { value: -1, label: '---Select one---' };

function CreateFormComponent({ dispatch, resources }: CreateFormProps) {
  const [resourceId, setResourceId] = useState(SELECT_PLACEHOLDER_OPTION.value);
  const [hours, setHours] = useState(0);
  const [createEnabled, setCreateEnabled] = useState(false);
  const sortedResources = [...resources].sort((a, b) => a.name.localeCompare(b.name));

  const options: CustomSelectOption[] = sortedResources.map(
    (resource) => ({ value: resource.id, label: resource.name })
  );

  return (
    <div className="border p-3">
      <div className="mb-3">
        <div>
          {/* lint doesn't recognize correctly the htmlFor when is linked to the Select library */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="popup-create-resource" className="d-block">
            Assign to:
            <CustomSelect
              defaultValue={SELECT_PLACEHOLDER_OPTION}
              options={[SELECT_PLACEHOLDER_OPTION, ...options]}
              inputId="popup-create-resource"
              onChange={(e) => {
                if (!e) return;
                const n = Number(e.value);
                setResourceId(n);
                setCreateEnabled(n > 0 && hours > 0);
              }}
            />
          </label>
        </div>
        <div>
          <label htmlFor="popup-create-hours-input" className="d-block">
            Hours per day:
            <input
              type="number"
              step="0.5"
              min="0"
              max="8"
              id="popup-create-hours-input"
              className="form-control"
              value={hours}
              onChange={(e) => {
                const n = Number(e.target.value);
                setHours(n);
                setCreateEnabled(resourceId > 0 && n > 0);
              }}
            />
          </label>
        </div>
      </div>
      <div className="form-check mb-3">
        <label className="form-check-label" htmlFor="popup-available-time-input">
          <input type="checkbox" className="form-check-input" id="popup-available-time-input" />
          Only available time
        </label>
      </div>
      <button type="button" className="btn btn-secondary" onClick={() => { dispatch({ type: 'closePopup' }); }}>Cancel</button>
      <button type="button" className="btn btn-success ms-3" disabled={!createEnabled} onClick={() => dispatch({ type: 'createSelection', hours, resourceId })}>Create</button>
    </div>
  );
}

export const CreateForm = React.memo(CreateFormComponent);
