import React from 'react';
import { Workload as WL, Selection, Action } from '../planner-data.interface';

type WorkloadProps = {
  workload: WL,
  todayStr: string,
  dateStr:string,
  resourceGroupId: number,
  resourceId: number,
  // eslint-disable-next-line react/no-unused-prop-types -- Actually used but not detected
  selection: Selection,
  roleId: number,
  dispatch: React.Dispatch<Action>
};

/**
 * Checks if given a selection state, the current cell is selected or not.
 * @returns true if the cell is selected.
 */
function checkSelection(props: WorkloadProps) {
  if (props.selection.startDate === null || props.selection.endDate === null) {
    return false;
  }

  return props.selection.resourceGroupId === props.resourceGroupId
  && props.selection.resourceId === props.resourceId
  && props.selection.roleId === props.roleId
  && props.selection.startDate <= props.dateStr
  && props.selection.endDate >= props.dateStr;
}

/**
 * Used as the second parameter of React.memo, it allows us to skip the re-rendering
 * of the Subtotal component, since there is a lot of them. This looks like a lot of
 * work, but is faster than the re-rendering plus the diffing algorithm in react.
 * @returns true to skip rendering, false to re-render.
 */
function shouldSkipRendering(prevProps: WorkloadProps, nextProps: WorkloadProps): boolean {
  // Skip rendering if trivially the same state
  if (prevProps === nextProps) {
    return true;
  }

  // Skip rendering if trivially the same workload and selection
  if (prevProps.workload === nextProps.workload && prevProps.selection === nextProps.selection) {
    return true;
  }

  // Check if selection didn't change
  const wasSelected = checkSelection(prevProps);
  const isSelected = checkSelection(nextProps);
  const isSelecting = prevProps.selection.isSelecting && nextProps.selection.isSelecting;
  if (isSelecting && (wasSelected === isSelected)) {
    return true;
  }

  // Any other case, re-render
  return false;
}

/**
 * Components that represents a workload, to be used inside a planner cell.
 */
function WorkloadComponent(props: WorkloadProps) {
  const isSelected = checkSelection(props);

  let reportedValue = '0';
  let plannedValue = '0';
  let showValues = false;

  if (props.workload) {
    if (props.dateStr < props.todayStr) {
      if (props.workload.plannedHours > 0 || props.workload.reportedHours > 0) {
        showValues = true;
        reportedValue = props.workload.reportedHours.toString();
        plannedValue = props.workload.plannedHours.toString();
      }
    } else if (props.workload.plannedHours > 0) {
      showValues = true;
      plannedValue = props.workload.plannedHours.toString();
    }
  }

  return (
    <div
      className={`workload m-auto ${isSelected ? 'selected' : ''}`}
      onMouseEnter={() => props.dispatch({
        type: 'mouseEnter', date: props.dateStr, resourceGroupId: props.resourceGroupId, resourceId: props.resourceId, roleId: props.roleId
      })}
      onMouseDown={() => props.dispatch({
        type: 'mouseDown', date: props.dateStr, resourceGroupId: props.resourceGroupId, resourceId: props.resourceId, roleId: props.roleId
      })}
      role="presentation"
    >
      <div className="text-center m-auto">
        { showValues && (
        <>
          { props.dateStr < props.todayStr && <div>{reportedValue}</div> }
          <div>{plannedValue}</div>
        </>
        )}
      </div>
    </div>
  );
}

export const Workload = React.memo(WorkloadComponent, shouldSkipRendering);
