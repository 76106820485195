window.addEventListener('DOMContentLoaded', () => {
  changeTabOnPageLoad();
  changeHashOnShowTab();
});

function changeTabOnPageLoad() {
  // read hash from page load and change tab
  const { hash } = document.location;
  if (hash) {
    $(`.nav-tabs a[href="${hash}"]`).tab('show');
  }
}

function changeHashOnShowTab() {
  // Change hash for page-reload
  $('.nav-tabs a').on(/* Tab.Events.shown */ 'shown.bs.tab', (e) => {
    window.location.hash = (e.target as HTMLAnchorElement).hash;

    const $target = $(e.target);
    const url = $target.data('url') as string | undefined;
    if (url) {
      void $.get(url, (data: string) => {
        // $target is <a>, should have href:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        $($target.attr('href')!).html(data);
      });
    }
  });
}
