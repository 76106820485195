import React, { useRef } from 'react';
import { useScrolling } from 'react-use';
import { PlannerData, Action, Selection } from '../planner-data.interface';
import { dateStr, floatFormat, monthName } from '../utils';
import { Row } from './row';
import { Cell } from './cell';
import { Workload } from './workload';
import { DateHeader } from './date-header';
import { Subtotal } from './subtotal';
import { StickyTopElement } from '../../helpers/Sticky/StickyTopElement';

// If you change this vaue, also change $planner-cell-size in planner.scss
// TODO: remove this size from the scss. Add a prop to <Cell />.
const cellSize = 40; // px

export interface ScrollablePaneProps {
  resourceGroups: PlannerData['resourceGroups'];
  datesRange: Date[];
  selection: Selection;
  dispatch: React.Dispatch<Action>;
  holidays: PlannerData['holidays'];
  totals: PlannerData['totals'];
}

/**
 * Sub-component that contains the rows of the scrollable section of the planner component.
 */
function ScrollablePaneComponent({
  resourceGroups, datesRange, selection, dispatch, holidays, totals
}: ScrollablePaneProps) {
  const todayStr = dateStr(new Date());
  const scrollablePaneRef = useRef(null);
  const IscrollablePaneRefScrolling = useScrolling(scrollablePaneRef);

  // Count number of days we are visualizing for each month
  const monthsDict = datesRange.reduce(
    (dict: { [key: string]: { count: number, sample: Date } }, date) => {
      const key = date.toJSON().substring(0, 7); // yyyy-mm
      return {
        ...dict,
        [key]: {
          count: (dict[key]?.count || 0) + 1,
          sample: dict[key]?.sample || date
        }
      };
    }, {});

  return (
    <div className="scrollable-pane" ref={scrollablePaneRef}>
      {/* First row: Month name */}
      <StickyTopElement isParentScrolling={IscrollablePaneRefScrolling}>
        <Row key="months-header">
          {Object.keys(monthsDict).sort().map((key) => (
            <Cell
              key={key}
              style={
              { width: `${cellSize * monthsDict[key].count}px` }
            }
            >
              <div className="ps-3">
                {monthName(monthsDict[key].sample)}
              </div>
            </Cell>
          )
          )}
        </Row>

        {/* Second row: Date and day name */}
        <Row key="dates-header">
          {datesRange.map((d) => (
            <Cell key={d.toJSON()}><DateHeader date={d} disabledDays={holidays} /></Cell>
          ))}
        </Row>
      </StickyTopElement>

      {/* N-rows: Resource groups and their resources */}
      {resourceGroups.map((resourceGroup) => (
        <React.Fragment key={`rg${resourceGroup.id}`}>
          <Row key={`rg${resourceGroup.id}`}>
            {/* Empty cells for resource group row. TODO: Show aggregates. */}
            {datesRange.map((date) => {
              const ds = dateStr(date);
              return (
                <Cell key={ds}>
                  <Subtotal
                    workload={{
                      ...(resourceGroup.workloads[ds]),
                      reportedHours: resourceGroup.resources.reduce(
                        (prev, curr) => prev + (curr.workloads[ds]?.reportedHours || 0),
                        0
                      )
                    }}
                    todayStr={todayStr}
                    dateStr={ds}
                    dispatch={dispatch}
                    selection={selection}
                    resourceGroupId={resourceGroup.id}
                    holidays={holidays}
                  />
                </Cell>
              );
            })}
          </Row>

          {/* Cells with Workload components for the resources rows */}
          {resourceGroup.resources.map((resource) => (
            <Row key={`rg${resourceGroup.id}-ro${resource.roleId}-r${resource.id}`}>
              {datesRange.map((date) => {
                const ds = dateStr(date);
                return (
                  <Cell key={ds}>
                    <Workload
                      workload={resource.workloads[ds]}
                      todayStr={todayStr}
                      dateStr={ds}
                      dispatch={dispatch}
                      selection={selection}
                      resourceGroupId={resourceGroup.id}
                      resourceId={resource.id}
                      roleId={resource.roleId}
                    />
                  </Cell>
                );
              })}
            </Row>
          ))}
        </React.Fragment>
      ))}

      { /* Last Row: Totals of each column */}
      <Row key="columns-totals">
        {datesRange.map((date) => {
          const ds = dateStr(date);
          return (
            <Cell key={ds}>
              <p className="m-auto">
                {
                  totals.workloads[ds]?.plannedHours > 0 ? floatFormat(totals.workloads[ds]?.plannedHours) : ''
                }
              </p>
            </Cell>
          );
        })}
      </Row>
    </div>
  );
}

export const ScrollablePane = React.memo(ScrollablePaneComponent);
