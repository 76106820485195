import React from 'react';
import { Action } from '../../../planner-data.interface';

export interface DeleteFormProps {
  dispatch: React.Dispatch<Action>;
}

function DeleteFormComponent({ dispatch }: DeleteFormProps) {
  return (
    <div className="border p-3">
      <p>Confirm delete:</p>
      <button type="button" className="btn btn-secondary" onClick={() => dispatch({ type: 'closePopup' })}>Cancel</button>
      <button type="button" className="btn btn-danger ms-3" onClick={() => dispatch({ type: 'deleteSelection' })}>Delete</button>
    </div>
  );
}

export const DeleteForm = React.memo(DeleteFormComponent);
